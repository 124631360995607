<template>
  <div
    v-if="pptoToastModalOpen && !inEnPrimeur"
    class="PPTOToastification-container"
  >
    <!-- Copy of vue-toastification popup container -->
    <div
      class="Vue-Toastification__container bottom-center"
      style="z-index: 199;"
    >
      <div
        class="Vue-Toastification__toast Vue-Toastification__toast--default bottom-center"
      >
        <nuxt-link :to="pptoLink" @click.native="close">
          <div role="alert" class="Vue-Toastification__toast-component-body">
            <div>
              <!-- Custom body content -->
              <div class="row">
                <div class="columns small-4">
                  <ul class="row cellar-grid">
                    <li
                      v-for="item in 12"
                      :key="item"
                      class="columns medium-3 small-3 cellar-box"
                    >
                      <span v-if="item <= unavailableSlots" class="dot"></span>
                    </li>
                  </ul>
                </div>
                <div class="columns small-8">
                  <span v-if="firstPptoOrder.is_as_you_go">
                    <span style="text-decoration: underline;">
                      Add
                      <Price
                        :amt="firstPptoOrder.remaining_amount_for_free_shipping"
                      />
                      to order #{{ firstPptoOrder.parent_order }}</span
                    >, to get shipping included.
                  </span>
                  <span v-else>
                    <span style="text-decoration: underline;">
                      Add {{ availableSlots }} bottle{{
                        availableSlots === 1 ? '' : 's'
                      }}
                      to your
                      {{
                        !isPendingWeather ? pptoPlaceBy + ' ' : ''
                      }}order</span
                    >. Shipping included.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nuxt-link>
        <button
          aria-label="close"
          class="Vue-Toastification__close-button"
          @click="close()"
        >
          ×
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Price from '~/../common/components/Price.vue'

export default {
  name: 'PPTOToastification',
  components: {
    Price,
  },
  computed: {
    ...mapGetters([
      'pptoOrders',
      'pptoLink',
      'showPPTO',
      'firstPptoOrder',
      'pptoToastModalOpen',
    ]),
    isPendingWeather() {
      return (
        this.firstPptoOrder &&
        ['pending_weather', 'pending_fall_release'].includes(
          this.firstPptoOrder.status
        )
      )
    },
    pptoPlaceBy() {
      if (this.firstPptoOrder) {
        return this.$moment(this.firstPptoOrder.requested_delivery_date).format(
          'MMMM D'
        )
      }
      return ''
    },
    availableSlots() {
      if (this.firstPptoOrder) {
        return this.firstPptoOrder.ppto_slots_available
      }
      return 0
    },
    unavailableSlots() {
      return 12 - this.availableSlots
    },
    inEnPrimeur() {
      return this.$route.path.includes('en-primeur')
    },
  },
  mounted() {
    this.$store.dispatch('setPptoToastOpen', true)
  },
  methods: {
    close() {
      this.$store.dispatch('setPptoToastOpen', false)
    },
  },
}
</script>
